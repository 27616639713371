import React from "react";
import Drawer from "react-modern-drawer";
import MyAccountSideOptions from "../../Pages/myAccount/sideBarSection";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function MyAccount({ userAction, setUserAction }) {
  const { width } = useWindowDimensions();

  const handleClose = () => {
    setUserAction({});
  };

  return (
    <>
      <Drawer
        open={width <= 992 && userAction?.action === "showMyAccountOptions"}
        onClose={handleClose}
        lockBackgroundScroll={true}
        direction="right"
        className="myAccountDrawer"
      >
        <MyAccountSideOptions
          wrapperClassName="d-block bg-white text-dark"
          handleClose={handleClose}
        />
      </Drawer>
    </>
  );
}
