import React, { useState } from "react";
import { IoIosShareAlt } from "react-icons/io";
import SharePop from "../Modals/sharePop";

export default function ShareBtn() {
  const [userAction, setUserAction] = useState(null);

  const handleShare = () => {
    setUserAction({ action: "showSharePop" });
  };

  return (
    <>
      <button
        className="primaryBtn d-flex align-items-center rounded mx-2 px-3 py-1"
        onClick={handleShare}
      >
        Share <IoIosShareAlt className="ms-2" />
      </button>

      {userAction?.action === "showSharePop" && (
        <SharePop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
