import React from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import { handleLoginPop } from "../../utils/helpers";
import Signin from "../../Pages/Auth/Signin";

const LoginPop = () => {
  const { showLoginPop } = useSelector((state) => state.common);

  const handleClosePop = () => {
    handleLoginPop(false);
  };

  return (
    <>
      <Modal
        show={showLoginPop}
        onHide={handleClosePop}
        backdrop="static"
        keyboard={false}
        centered
        size="md"
        className="delete-pop"
      >
        <Modal.Body className="bg-secondary-light rounded p-0 ">
          <div className="pointer text-end">
            <CloseIcon onClick={handleClosePop} />
          </div>
          <div className="position-relative p-3 py-4">
            <Signin loginFromPop={true} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginPop;
