import React from "react";
import Skeleton from "react-loading-skeleton";
import { skeletonBaseColor, skeletonHighlightColor } from "../../utils/constants";

export default function StoryDetailsSkeleton({ className = "" }) {
  return (
    <div className={`w-100 ${className}`}>
      <div className="d-flex align-items-center">
        <Skeleton
          circle
          className="img-medium"
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
        <div className="ms-2">
          <Skeleton
            width={150}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
          <Skeleton
            width={150}
            baseColor={skeletonBaseColor}
            highlightColor={skeletonHighlightColor}
          />
        </div>
      </div>
      <div className="my-4">
        <Skeleton
          height={35}
          count={1}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
        />
      </div>
      <div className="my-2">
        <Skeleton count={3} baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
        <Skeleton
          count={1}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          width={"35%"}
        />
      </div>
      <div className="my-4">
        <Skeleton count={5} baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
      </div>
      <div className="my-2">
        <Skeleton count={1} baseColor={skeletonBaseColor} highlightColor={skeletonHighlightColor} />
        <Skeleton
          count={1}
          baseColor={skeletonBaseColor}
          highlightColor={skeletonHighlightColor}
          width={"80%"}
        />
      </div>
    </div>
  );
}
