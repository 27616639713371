import * as yup from "yup";
import { contactUsReason, passwordregex, storyReportReasons, userReportReasons } from "./constants";
import phone from "phone";

export const profileSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  mobile: yup
    .string()
    .test("mobile-validation", "Invalid mobile number", (value) => phone(value).isValid)
    .required("Mobile number is required"),
  birth_date: yup.string().required("Birth date is required"),
  gender: yup.string().oneOf(["male", "female", "other"]).required("Gender is required"),
  address: yup.string().required("Address is required"),
  avatar: yup.mixed().test(
    "isValid-avatar",
    "Avatar must be either an object with a valid URL and name or an empty string.",
    (value) =>
      value === "" ||
      (typeof value === "object" &&
        yup
          .object()
          .shape({
            url: yup.string().url().required(),
            name: yup.string().required(),
          })
          .isValidSync(value))
  ),
});

export const registrationSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  email: yup.string().email().required("Email is required"),
  mobile: yup
    .string()
    .test("mobile-validation", "Invalid mobile number", (value) => phone(value).isValid)
    .required("Mobile number is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 character long")
    .matches(passwordregex, "Invalid password"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password does not match"),
  birth_date: yup.string(),
  gender: yup.string().oneOf(["male", "female", "other"]),
  address: yup.string(),
  avatar: yup
    .mixed()
    .test(
      "isValid-avatar",
      "Avatar must be either an object with a valid URL and name or an empty string.",
      (value) => {
        !value ||
          (value &&
            yup
              .object()
              .shape({
                url: yup.string().url().required(),
                name: yup.string().required(),
              })
              .isValidSync(value));
      }
    ),
});

export const loginSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 character long")
    .matches(passwordregex, "Invalid password"),
  remember: yup.boolean(),
});

export const resetPassowrdSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
  otp: yup.string().min(6).max(6).required("OTP is required"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 character long")
    .matches(passwordregex, "Invalid password"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password does not match"),
});

export const otpValidationSchema = yup.object().shape({
  otp: yup
    .string()
    .min(6, "OTP must be 6 digits long")
    .max(6, "OTP must be 6 digits long")
    .required("OTP is required"),
});

export const emailSchema = yup.object().shape({
  email: yup.string().email().required("Email is required"),
});

export const updatePasswordSchema = yup.object().shape({
  oldPassword: yup
    .string()
    .required("Old password is required")
    .min(8, "Invalid password")
    .matches(passwordregex, "Invalid password"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Password must be atleast 8 character long")
    .matches(passwordregex, "Invalid password"),
  confirmPassword: yup
    .string()
    .required("Confirm Password is required")
    .oneOf([yup.ref("password"), null], "Password does not match"),
});

export const postStorySchema = yup.object().shape({
  title: yup.string().min(5).max(150).required("Title is required"),
  description: yup.string().min(50).required("Please let your inner voice come out"),
  category: yup.string().required("Select your feeling"),
  anonymousSharing: yup.boolean(),
  isPrivate: yup.boolean(),
});

export const reportStorySchema = yup.object().shape({
  reason: yup
    .string()
    .oneOf(storyReportReasons.map((item) => item.value))
    .required("Please select reason"),
  description: yup.string().min(15).max(500).required("Required"),
});

export const reportUserSchema = yup.object().shape({
  reason: yup
    .string()
    .oneOf(userReportReasons.map((item) => item.value))
    .required("Please select reason"),
  description: yup.string().min(15).max(500).required("Required"),
});

export const conatctUsSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  mobile: yup
    .string()
    .test("mobile-validation", "Invalid mobile number", (value) => phone(value).isValid)
    .required("Mobile number is required"),
  email: yup.string().email().required("Email is required"),
  reason: yup
    .string()
    .oneOf(contactUsReason.map((item) => item.value))
    .required("Reason is required"),
  comment: yup.string().min(10).max(500).required("Comment is required"),
});
