import React from "react";
import Skeleton from "react-loading-skeleton";

export default function QuerySkeleton() {
  return (
    <>
      <div className="storyCard pointer border-bottom border-white py-4">
        <Skeleton baseColor="#d3d3d3" highlightColor="#fff" className="my-3" height={28} />
        <Skeleton count={3} baseColor="#d3d3d3" highlightColor="#fff" />
      </div>
    </>
  );
}
