import React from "react";
import Skeleton from "react-loading-skeleton";

export default function CardSkeleton() {
  return (
    <>
      <div className="mainStoryCard rounded bg-white mb-3 pointer p-2 p-lg-3 m-1">
        <div className="d-flex align-items-center">
          <Skeleton circle className="img-medium" baseColor="#d3d3d3" highlightColor="#fff" />

          <div className="ms-2">
            <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
            <Skeleton width={150} baseColor="#d3d3d3" highlightColor="#fff" />
          </div>
        </div>

        <Skeleton baseColor="#d3d3d3" highlightColor="#fff" className="my-3" height={28} />
        <Skeleton count={2} baseColor="#d3d3d3" highlightColor="#fff" />
        <Skeleton count={1} width={"50%"} baseColor="#d3d3d3" highlightColor="#fff" />
      </div>
    </>
  );
}
