import React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

export default function AppLogo() {
  const { width } = useWindowDimensions();

  return (
    <div
      className={`${width >= 400 ? "d-flex" : "d-block"} align-items-center justify-content-center`}
    >
      <img src="/appLogo.png" className="img-fluid h-100 me-2" style={{ maxHeight: 70 }} />
      <h2 className="" style={{ fontSize: 50 }}>
        feelingHub
      </h2>
    </div>
  );
}
