import { getToken } from "firebase/messaging";
import { messaging, vapidKey } from "./config";
import { errorMsg } from "../utils/toastMessage";

const requestToken = async () => {
  let firebaseToken = "";
  try {
    firebaseToken = await getToken(messaging, { vapidKey });
  } catch (error) {
    if (error.code === "messaging/permission-blocked") {
      errorMsg("Notification permission denied");
    }
    console.log("firebase getToken error", error);
  }

  return firebaseToken;
};

export default requestToken;
