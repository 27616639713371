import { createSlice } from "@reduxjs/toolkit";
import { getChatsList, getMessageHistory } from "./thunk";

const initialState = {
  chatsList: {},
  loadingChatsList: false,
  messageHistory: [],
  loadingMessageHistory: false,
};

const chatsSlice = createSlice({
  name: "chatsSlice",
  initialState: { ...initialState },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getChatsList.pending, (state, action) => {
        state.loadingChatsList = true;
      })
      .addCase(getChatsList.fulfilled, (state, action) => {
        state.chatsList = action.payload?.data || {};
        state.loadingChatsList = false;
      })
      .addCase(getChatsList.rejected, (state, action) => {
        state.loadingChatsList = false;
      });
    builder
      .addCase(getMessageHistory.pending, (state, action) => {
        state.loadingMessageHistory = true;
      })
      .addCase(getMessageHistory.fulfilled, (state, action) => {
        state.messageHistory = action.payload?.data || {};
        state.loadingMessageHistory = false;
      })
      .addCase(getMessageHistory.rejected, (state, action) => {
        state.loadingMessageHistory = false;
      });
  },
});

export default chatsSlice.reducer;
