import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { BsFlagFill } from "react-icons/bs";
import { storyDetailsRoute } from "../../routes/routesPath";
import ReportStoryPop from "../Modals/reportStoryPop";
import { getStorySlug, handleLoginPop } from "../../utils/helpers";
import useAuth from "../../hooks/useAuth";
import { handleApiRequest } from "../../services/handleApiRequest";
import AddReaction from "../common/addReaction";
import StoryCardHeader from "./storyCardHeader";
import { manageBookmark } from "../../redux/bookmarks/thunk";

export default function StoryCard({ story = {}, showReaction = "", setShowReaction }) {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();

  const [userAction, setUserAction] = useState(null);
  const [storyDetails, setStoryDetails] = useState({ ...story });

  const handleBookmark = async (e) => {
    e.stopPropagation();
    if (!isAuthenticated) return handleLoginPop(true);

    setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));

    const response = await handleApiRequest(manageBookmark, { storyId: storyDetails._id });
    if (!response.status) {
      setStoryDetails((prev) => ({ ...prev, isBookmarked: !prev.isBookmarked }));
    }
  };

  return (
    <>
      <div
        className="mainStoryCard rounded mb-3 pointer p-2 p-lg-3 m-1"
        onClick={() =>
          navigate(`${storyDetailsRoute}/${getStorySlug(storyDetails.title)}/${storyDetails._id}`)
        }
      >
        <StoryCardHeader story={storyDetails} showStatus={false} />

        <h5 className="mt-3 mb-2">{storyDetails.title}</h5>
        <div
          className="storyDesc truncate-3"
          dangerouslySetInnerHTML={{ __html: storyDetails.description }}
        />

        <div className="storyAction d-flex align-items-center justify-content-between my-3">
          <div className="d-flex align-items-center">
            <p className="">
              <span dangerouslySetInnerHTML={{ __html: storyDetails.category?.iconFilled }} />
              <span className="d-none d-sm-inline-block ms-1">{storyDetails.category?.name}</span>
            </p>
            <div className="position-relative">
              <AddReaction
                showReaction={showReaction}
                setShowReaction={setShowReaction}
                savedReaction={storyDetails.myReaction}
                reactionsCount={storyDetails?.reactionsCount}
                storyId={storyDetails._id}
                setStoryDetails={setStoryDetails}
              />
            </div>
            <p className="mainStoryCardAction" onClick={handleBookmark}>
              {storyDetails.isBookmarked ? (
                <>
                  <span>
                    <FaBookmark style={{ height: 22 }} />
                  </span>
                  <span className="d-none d-sm-inline-block">Unsave</span>
                </>
              ) : (
                <>
                  <span>
                    <FaRegBookmark style={{ height: 22 }} />
                  </span>
                  <span className="d-none d-sm-inline-block">Save</span>
                </>
              )}
            </p>
          </div>
          <div className="mainStoryCardAction">
            <p
              className="d-flex align-items-center"
              onClick={(e) => {
                e.stopPropagation();
                setUserAction({ action: "reportStory", id: storyDetails._id });
              }}
            >
              <span className="d-none d-sm-inline-block"> Report</span>
              <BsFlagFill style={{ height: 20, width: 20 }} />
            </p>
          </div>
        </div>
      </div>

      {userAction?.action === "reportStory" && (
        <ReportStoryPop userAction={userAction} setUserAction={setUserAction} />
      )}
    </>
  );
}
