import React from "react";
import { getUserImage } from "../../utils/helpers";
import { format_date, parseCamelKey } from "../../utils/formatersAndParsers";
import { useNavigate } from "react-router-dom";

export default function StoryCardHeader({ story = {}, showStatus = true }) {
  const navigate = useNavigate();

  return (
    <div className="mainStoryCardHeader position-relative d-flex justify-content-between">
      <div
        className="w-fit d-flex align-items-center"
        onClick={(e) => {
          e.stopPropagation();
          if (!story.anonymousSharing && !showStatus) {
            navigate(`/user/${story.user?._id}`);
          }
        }}
      >
        <img
          src={story.anonymousSharing ? getUserImage() : getUserImage(story.user)}
          alt={story.anonymousSharing ? "userImage" : story.user?.name}
          onError={(e) => {
            e.target.src = getUserImage();
          }}
          className="img-medium img-fluid rounded-circle"
        />
        <div className="ms-2">
          <p className="my-0 text-truncate" style={{ maxWidth: 150 }}>
            {story.anonymousSharing ? "Anonymous" : story.user?.name}
          </p>
          <p className="muted small my-0">Posted At: {format_date(story?.createdAt)}</p>
        </div>
      </div>
      {showStatus && (
        <p
          className={`position-absolute end-0 top-0 rounded-pill px-3 py-1 text-white ${
            story.status === "active"
              ? "bg-success"
              : story.status === "deleted" || story.status === "blocked"
              ? "bg-danger"
              : "bg-success"
          } `}
        >
          {parseCamelKey(story.status)}
        </p>
      )}
    </div>
  );
}
