import React from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isArray } from "../../utils/formatersAndParsers";
import { cmsPage, contactUsRoute, storiesRoute } from "../../routes/routesPath";
import {
  FacebookHandle,
  InstagramHandle,
  TelegramHandle,
  YoutubeHandle,
} from "../common/socialHandles";

export default function Footer() {
  return (
    <>
      <footer
        id="footerid"
        className="footer d-none d-sm-flex align-items-center justify-content-center"
      >
        <p className="m-0">© Copyright 2024 Feelinghub</p>
      </footer>
    </>
  );
}

export const SubFooter = () => {
  const { contentPagesList, categories } = useSelector((state) => state.common || {});

  return (
    <Row className="subFooter bg-dark text-white m-0 p-2 py-3 p-lg-5 d-none d-sm-flex">
      <Col sm={6} md={5} className="col-12">
        <h3 className="fw-normal">About Us</h3>
        <p className="my-3">
          While technology dominates our daily lives, We are trying to support those struggling with
          lonliness, mental issues, personal problems, or other challenges...
        </p>
        <div className="mt-3 d-flex align-items-center">
          <FacebookHandle />
          <InstagramHandle />
          <TelegramHandle />
          <YoutubeHandle />
        </div>
      </Col>
      <Col md={1} className="d-none d-md-block" />
      <Col sm={6} md={3} className="d-none d-sm-flex justify-content-end justify-content-md-start">
        <div>
          <h3 className="fw-normal">Quick Links</h3>
          <ul className="list-unstyled my-3">
            <Link to={`${contactUsRoute}`} className="text-decoration-none text-white my-1 d-block">
              Contact Us
            </Link>
            {isArray(contentPagesList).map((page) => (
              <Link
                key={page._id}
                to={`${cmsPage}/${page._id}`}
                className="text-decoration-none text-white my-1 d-block"
              >
                {page.title}
              </Link>
            ))}
          </ul>
        </div>
      </Col>
      <Col md={3} className="d-none d-md-flex justify-content-end justify-content-lg-start">
        <div>
          <h3 className="fw-normal">Feelings</h3>
          <ul className="list-unstyled my-3">
            {isArray(categories).map((category) => (
              <Link
                key={category._id}
                to={`${storiesRoute}?category=${category._id}`}
                className="text-decoration-none text-white my-1 d-block"
              >
                {category.specialName || category.name}
              </Link>
            ))}
          </ul>
        </div>
      </Col>
    </Row>
  );
};
