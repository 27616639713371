import React, { useEffect, useState } from "react";
import Select from "react-select";
import { parseCamelKey } from "../../utils/formatersAndParsers";

export default function MySelect({ ...props }) {
  const [value, setValue] = useState("");

  useEffect(() => {
    if (props.value && typeof props.value === "string") {
      setValue({ value: props.value, label: parseCamelKey(props.value) });
    }
    if (props.value && typeof props.value === "number") {
      setValue({ value: props.value, label: props.value });
    } else {
      setValue(props.value);
    }
  }, [props.value]);

  return (
    <Select
      className="w-100"
      classNamePrefix={`${props.classNamePrefix || "mySelect"}`}
      components={{
        IndicatorSeparator: () => null,
      }}
      {...props}
      value={value || ""}
      onChange={(selected) => {
        props.onChange(selected?.value || "");
      }}
    />
  );
}
