import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { addStoryRoute, chat, homeRoute, signinRoute, storiesRoute } from "../../routes/routesPath";
import useAuth from "../../hooks/useAuth";
import { getMyAccountPagePath, getUserImage, handleLoginPop } from "../../utils/helpers";
import MyAccount from "../sidebars/myAccount";
import useWindowDimensions from "../../hooks/useWindowDimensions";

export default function Header() {
  const navigate = useNavigate();
  const { isAuthenticated } = useAuth();
  const { width = 0 } = useWindowDimensions();
  const { userProfile = {} } = useSelector((state) => state.auth || {});

  const [userAction, setUserAction] = useState({});

  return (
    <>
      <header className="headerContainer px-2">
        <div className="header">
          <Link to={homeRoute} className="navbar-brand">
            <div className="d-flex align-items-center">
              <img className="mainLogo img-fluid" src={"/appLogo.png"} alt="logo" />
            </div>
          </Link>

          <div className="d-flex align-items-center">
            <div className="d-none d-sm-flex align-items-center">
              <div className="me-4">
                <button
                  className="textBtn headerLink"
                  onClick={() => {
                    navigate(storiesRoute);
                  }}
                >
                  Stories
                </button>
                <button
                  className="textBtn headerLink"
                  onClick={() => {
                    if (isAuthenticated) {
                      navigate(chat);
                    } else {
                      handleLoginPop(true);
                    }
                  }}
                >
                  Chat
                </button>
              </div>
              <button
                className="primaryBtn rounded-pill py-1 px-2"
                onClick={() => navigate(isAuthenticated ? addStoryRoute : signinRoute)}
              >
                Express your Feeling
              </button>
              {isAuthenticated ? (
                <>
                  <img
                    src={getUserImage(userProfile)}
                    alt={userProfile?.name}
                    onError={(e) => {
                      e.target.src = getUserImage();
                    }}
                    className="headerIcon pointer img-fluid mx-2"
                    onClick={() => {
                      if (width >= 992) {
                        navigate(getMyAccountPagePath());
                      } else {
                        setUserAction({ action: "showMyAccountOptions" });
                      }
                    }}
                  />
                </>
              ) : (
                ""
              )}
            </div>

            {!isAuthenticated && (
              <button
                className="bg-secondary text-white rounded-pill py-1 px-3 ms-2"
                style={{ border: "2px solid var(--secondary-color)" }}
                onClick={() => navigate(signinRoute)}
              >
                Login
              </button>
            )}
          </div>
        </div>
      </header>
      <MyAccount userAction={userAction} setUserAction={setUserAction} />
    </>
  );
}
