import React, { useContext, useEffect, useRef, useState } from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { debounce } from "lodash";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { BeatLoader, ClipLoader } from "react-spinners";
import InfiniteScroll from "react-infinite-scroll-component";
import EmojiPicker from "emoji-picker-react";
import { BsSendFill } from "react-icons/bs";
import { FaSearch, FaUser } from "react-icons/fa";
import { MdOutlineBlock } from "react-icons/md";
import { HiDotsVertical } from "react-icons/hi";
import noMessagePlaceholder from "../../assets/images/startChatting.png";
import noChatSelectedPlaceholder from "../../assets/images/startchatHolder.webp";
import searchUserGif from "../../assets/images/searchUser.gif";
import { Context } from "../../context/provider";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getUsersList } from "../../redux/common/thunk";
import { getChatsList, getMessageHistory } from "../../redux/chat/thunk";
import { getLastMessageDateTime, getUserImage, isArray } from "../../utils/helpers";
import UserHolderSkeleton from "../../Component/skeleton/userHolderSkeleton";
import { defaultPage } from "../../utils/constants";
import { format_date, format_time } from "../../utils/formatersAndParsers";
import { chat, otherUserProfile } from "../../routes/routesPath";
import BlockChat from "../../Component/Modals/blockChat";
import { successMsg } from "../../utils/toastMessage";

export default function Chat() {
  const emojiPickerRef = useRef();
  const messageBoxRef = useRef();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const receiverId = searchParams.get("receiver");
  const { socket = {} } = useContext(Context);

  const { userProfile = {} } = useSelector((state) => state.auth);

  const [userAction, setUserAction] = useState({});
  const [chatList, setChatList] = useState({});
  const [searchUser, setSearchUser] = useState("");
  const [selectedChat, setSelectedChat] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [newMessage, setNewMessage] = useState("");
  const [messages, setMessages] = useState({});
  const [isTyping, setIsTyping] = useState(false);
  const [openEmojiPicker, setOpendEmojiPicker] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState({
    ...defaultPage,
    orderBy: "updatedBy",
  });

  const getMessageListHeight = () => {
    const height = `calc(100% - 1rem - 58px - ${
      messageBoxRef?.current?.scrollHeight
        ? Math.min(messageBoxRef?.current?.scrollHeight + 2, 132)
        : 38
    }px)`;

    return height;
  };

  const handleChange = (e) => {
    setSearchUser(e.target.value || "");
  };

  const handleDebounceTyping = debounce(() => {
    if (!socket) return;
    setIsTyping(false);
    socket.emit("typing", { receiver: receiverId, typingStatus: false });
  }, 1200);

  const handleMessageChange = (e) => {
    setNewMessage(e.target.value || "");

    if (!isTyping && socket) {
      setIsTyping(true);
      socket.emit("typing", { receiver: receiverId, typingStatus: true });
    }
  };

  const handleSendMessage = (event) => {
    event?.preventDefault();

    if (!socket || !selectedChat || !newMessage) return;

    setMessages((prev) => ({
      totalCount: (prev.totalCount || 0) + 1,
      records: [
        {
          message: newMessage,
          sender: userProfile._id,
          createdAt: new Date(),
          _id: new Date().getTime(),
          chat: selectedChat,
        },
        ...(prev.records || []),
      ],
    }));

    socket.emit(
      "sendMessage",
      {
        message: newMessage,
        chatId: selectedChat,
        sender: userProfile._id,
        receiver: receiverId,
      },
      (response) => {
        console.log("msg sent", response);
        handleUpdateChatList(response.data);
      }
    );
    setNewMessage("");
    socket.emit("typing", { receiver: receiverId, typingStatus: false });
  };

  const handleChatList = async () => {
    const request = {
      userId: userProfile._id,
    };
    const response = await handleApiRequest(getChatsList, request);

    if (response.status) {
      setChatList(response.data || {});
    }
  };

  const handleMessageHistory = async () => {
    const request = {
      ...paginationDetails,
      chatId: selectedChat,
    };
    const response = await handleApiRequest(getMessageHistory, request);

    if (response.status) {
      let { records = [] } = response.data;
      setPaginationDetails((prev) => ({ ...prev, page: prev.page + 1 }));

      if (paginationDetails.page === 1) {
        setMessages({ ...response.data, records: records });
      } else {
        setMessages((prev) => {
          return { ...prev, records: [...(prev.records || []), ...records] };
        });
      }
    }
  };

  const handleUserUpdateUserStatus = (userToUpdate, updatedFields = {}) => {
    setChatList((prev) => {
      const updatedChatIndex = isArray(prev.records)?.findIndex(
        (chat) => chat.otherUser?._id === userToUpdate
      );

      let updatedChatList = [...(prev.records || [])];
      if (updatedChatIndex >= 0) {
        updatedChatList[updatedChatIndex] = {
          ...(updatedChatList[updatedChatIndex] || {}),
          ...updatedFields,
        };
      }

      return {
        ...prev,
        records: updatedChatList,
      };
    });

    if (selectedUser && selectedUser._id === userToUpdate) {
      setSelectedUser((prev) => {
        return { ...prev, ...updatedFields };
      });
    }
  };

  const handleSearchUsers = debounce(async () => {
    const request = {
      limit: 20,
      search: searchUser,
    };
    const response = await handleApiRequest(getUsersList, request);
    if (response.status) {
      setChatList(response.data);
    }
  }, [1500]);

  const handleStartChat = (user) => {
    if (receiverId !== user._id) {
      setMessages({});
      setSelectedUser(user);
      setPaginationDetails({ ...defaultPage, orderBy: "updatedBy" });
      navigate(`${pathname}?receiver=${user._id}`, { replace: true });
    }
  };

  const handleUpdateChatList = (message) => {
    if (chatList.totalCount > 0) {
      setChatList((prev) => {
        let newChatList = [...(prev.records || [])];

        const latestChatIndex = newChatList?.findIndex((chat) => chat._id === message.chat);
        if (latestChatIndex >= 0) {
          const latestChat = newChatList[latestChatIndex];

          newChatList?.splice(latestChatIndex, 1);
          newChatList = [{ ...latestChat, lastMessage: message }, ...(newChatList || [])];
        }

        return { ...prev, records: newChatList };
      });
    } else {
      handleChatList();
    }
  };

  const handleNewMessage = (message) => {
    console.log("new message received", message);

    if (message.sender === selectedUser._id) {
      setMessages((prev) => ({
        records: [message, ...(prev.records || [])],
        totalCount: (prev.totalCount || 0) + 1,
      }));
    }

    handleUpdateChatList(message);
  };

  const handleBlockUser = async (blockStatus) => {
    if (!socket || !selectedChat) return setUserAction({});

    socket.emit(
      "blockUser",
      { chatId: selectedChat, blockedUserId: receiverId, blockStatus, userId: userProfile._id },
      (response) => {
        console.log("response", response);
        if (response.status) {
          setUserAction({});
          successMsg("This chat is blocked!!");
          navigate(`${chat}`);

          handleUserUpdateUserStatus(receiverId, {
            blockedUser: blockStatus === "block" ? receiverId : null,
          });
        }
      }
    );
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setOpendEmojiPicker(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  useEffect(() => {
    const textarea = messageBoxRef.current;
    if (textarea) {
      textarea.style.height = "36px";
      textarea.style.height = `${textarea.scrollHeight + 2}px`;
    }

    handleDebounceTyping();

    return () => handleDebounceTyping.cancel();
  }, [newMessage, messageBoxRef.current]);

  useEffect(() => {
    if (searchUser) {
      handleSearchUsers();
    } else if (userProfile._id && !searchUser) {
      handleChatList();
    }

    return () => handleSearchUsers.cancel();
  }, [searchUser, userProfile]);

  useEffect(() => {
    if (selectedChat) {
      handleMessageHistory();
    } else {
      setMessages({ totalCount: 0, records: [] });
    }
  }, [selectedChat]);

  useEffect(() => {
    if (selectedChat && chatList?.totalCount > 0) {
      const chat = isArray(chatList?.records)?.find((chat) => chat._id === selectedChat);

      if (chat) {
        setSelectedUser({
          ...chat.otherUser,
          isOnline: chat.isOnline,
          blockedUser: chat.blockedUser,
        });
      }
    }
  }, [chatList, selectedChat]);

  useEffect(() => {
    if (socket) {
      socket.on("newMessage", handleNewMessage);

      socket.on("user connected", (user) => {
        console.log("user connected", user);
        handleUserUpdateUserStatus(user, { isOnline: true });
      });

      socket.on("user disconnected", (user) => {
        console.log("user disconnected", user);
        handleUserUpdateUserStatus(user, { isOnline: false });
      });

      socket.on("typing", (response) => {
        setSelectedUser((prev) => ({ ...(prev || {}), isTyping: response.typingStatus }));
        console.log("typing response", response);
      });

      socket.on("blockUser", (response) => {
        const { blockStatus, chatId, blockedUserId, userId } = response;
        console.log(`user is ${blockStatus}`, response);

        handleUserUpdateUserStatus(userId, {
          blockedUser: blockStatus === "block" ? blockedUserId : null,
        });
      });

      return () => {
        // Cleanup socket listener on unmount or dependency change
        // socket.off("newMessage", handleNewMessage);
        socket.off("newMessage");
        socket.off("user connected");
        socket.off("user disconnected");
        socket.off("typing");
        socket.off("blockUser");
      };
    }
  }, [socket, selectedUser, chatList]);

  useEffect(() => {
    if (!receiverId && selectedChat) {
      setSelectedChat();
      setSelectedUser();
    }

    if (userProfile._id && socket && receiverId) {
      try {
        socket.emit(
          "joinChat",
          {
            senderId: userProfile._id,
            receiverId: receiverId,
          },
          (response) => {
            console.log("response", response);
            if (response.chatId && selectedChat !== response.chatId) {
              setSelectedChat(response.chatId);
              setSelectedUser((prev) => ({ ...(prev || {}), isOnline: response.isOnline }));
              setPaginationDetails((prev) => ({ ...prev, page: 1 }));
            }
          }
        );
      } catch (error) {
        console.log("error", error);
      }
    }
  }, [userProfile, socket, receiverId]);

  //   let hex = ":grinning:".codePointAt(0).toString(16)
  // let emo = String.fromCodePoint("0x" + "263a-fe0f");
  // console.log(emo);

  return (
    <>
      <section className="">
        <Row className="m-0">
          <Col sm={6} md={5} lg={4} className={`p-0 ${receiverId ? "d-none d-sm-block" : ""}`}>
            <div className="mainBodyHeight border pb-2 overflow-auto">
              <div className="py-2 position-sticky bg-white top-0 pt-3 px-3" style={{ zIndex: 9 }}>
                <div className="d-flex align-items-center shadow-sm border rounded">
                  <input
                    type="text"
                    className="form-control border-0 outline-0 shadow-none "
                    placeholder="Search by name or email"
                    value={searchUser}
                    onChange={handleChange}
                  />
                  <button className="textBtn">
                    <FaSearch />
                  </button>
                </div>
              </div>

              <div className="px-3">
                {chatList.totalCount > 0 ? (
                  isArray(chatList.records).map((chat) => {
                    const otherUser = chat.otherUser || chat || {};

                    return (
                      <div
                        key={chat._id}
                        className="pointer d-flex align-items-center shadow-sm my-2 px-2 py-1"
                        onClick={() => {
                          handleStartChat({ ...otherUser, isOnline: chat.isOnline });
                        }}
                      >
                        <div className="position-relative">
                          <img
                            src={getUserImage(otherUser)}
                            alt={otherUser.name}
                            className="img-fluid img-medium"
                            onError={(e) => {
                              e.target.src = getUserImage();
                            }}
                          />
                          {chat.isOnline ? (
                            <p
                              className="dot-medium dot-online border border-white position-absolute"
                              style={{ bottom: 5, right: 3 }}
                            />
                          ) : (
                            <p
                              className="dot-medium bg-secondary-default border border-white position-absolute"
                              style={{ bottom: 5, right: 3 }}
                            />
                          )}
                        </div>
                        <div className="mx-2 w-100">
                          <p className="d-flex align-items-center justify-content-between m-0">
                            <span className="truncate-1">{otherUser.name}</span>
                            <span className="text-small text-nowrap">
                              {getLastMessageDateTime(chat.lastMessage?.createdAt)}
                            </span>
                          </p>
                          <p className="truncate-1 text-muted text-medium m-0 text-break">
                            {chat.lastMessage?.message || otherUser.bio || ""}
                          </p>
                        </div>
                      </div>
                    );
                  })
                ) : chatList.totalCount === 0 ? (
                  <div className="d-flex flex-column align-items-center justify-content-center text-center h-100">
                    <img src={searchUserGif} alt="search users gif" className="img-fluid" />
                    <h6 className="m-0">
                      Search user with their Name or Email <br /> to start chatting
                    </h6>
                  </div>
                ) : (
                  Array.from({ length: 10 }, (_, i) => <UserHolderSkeleton key={i} />)
                )}
              </div>
            </div>
          </Col>
          <Col sm={6} md={7} lg={8} className={`p-0 ${!receiverId ? "d-none d-sm-block" : ""}`}>
            <div className="mainBodyHeight border overflow-auto">
              {selectedChat && selectedUser ? (
                <>
                  {/* Chat header */}
                  <div className="d-flex align-items-center shadow-sm p-2">
                    <img
                      src={getUserImage(selectedUser)}
                      alt={selectedUser.name}
                      className="img-fluid img-small"
                      onError={(e) => {
                        e.target.src = getUserImage();
                      }}
                    />
                    <div className="w-100 mx-2 d-flex align-items-center justify-content-between">
                      <div>
                        <p className="truncate-1 m-0">{selectedUser?.name}</p>
                        <div className="d-flex align-items-center m-0">
                          {selectedUser.isOnline ? (
                            <>
                              <p className="dot dot-online me-1" />
                              <p className="text-muted text-small m-0 d-flex align-items-center">
                                Online
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="dot bg-secondary-default me-1" />
                              <p className="text-muted text-small m-0 d-flex align-items-center">
                                Inactive
                              </p>
                            </>
                          )}
                        </div>
                      </div>

                      <Dropdown>
                        <Dropdown.Toggle className="bg-transparent border-0 text-dark">
                          <HiDotsVertical className="h5" />
                        </Dropdown.Toggle>

                        <Dropdown.Menu align="end">
                          {messages.totalCount > 0 && !selectedUser.blockedUser && (
                            <Dropdown.Item
                              as="p"
                              className="pointer m-0 px-2 py-1 d-flex align-items-center"
                              onClick={() => {
                                setUserAction({ action: "blockUser", chatId: selectedChat });
                              }}
                            >
                              <MdOutlineBlock className="me-2" />
                              Block User
                            </Dropdown.Item>
                          )}
                          {selectedUser.blockedUser === receiverId && (
                            <Dropdown.Item
                              as="p"
                              className="pointer m-0 px-2 py-1 d-flex align-items-center"
                              onClick={() => {
                                setUserAction({ action: "unblockUser", chatId: selectedChat });
                              }}
                            >
                              <MdOutlineBlock className="me-2" />
                              Unblock User
                            </Dropdown.Item>
                          )}
                          <Dropdown.Item
                            as="p"
                            className="pointer m-0 px-2 py-1 d-flex align-items-center"
                            onClick={() => {
                              navigate(`${otherUserProfile}/${selectedUser?._id}`);
                            }}
                          >
                            <FaUser className="me-2" />
                            Visit Profile
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </div>

                  {/* chat body */}
                  <div
                    id="messageList"
                    className="overflow-auto d-flex flex-column-reverse px-1"
                    style={{
                      height: getMessageListHeight(),
                    }}
                  >
                    {selectedUser.isTyping && (
                      <span className="typingIndicatorCOntainer w-fit pt-2 pb-1 px-3 rounded-pill shadow-sm m-2">
                        <BeatLoader
                          className="typingIndicator"
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </span>
                    )}

                    {messages.totalCount > 0 ? (
                      <>
                        <InfiniteScroll
                          dataLength={messages?.records?.length}
                          next={handleMessageHistory}
                          style={{ display: "flex", flexDirection: "column-reverse" }}
                          inverse={true}
                          hasMore={(messages.records?.length || 0) < (messages.totalCount || 10)}
                          loader={
                            <div className="text-center my-4">
                              <ClipLoader
                                color={"var(--primary-color)"}
                                cssOverride={{ borderWidth: 5 }}
                                aria-label="Loading Spinner"
                                data-testid="loader"
                              />
                            </div>
                          }
                          scrollableTarget="messageList"
                        >
                          {isArray(messages?.records).map((message, i) => {
                            const isMsgSent = message.sender === userProfile._id;

                            const isNewDateStarted =
                              i > 0 &&
                              new Date(message.createdAt).getDate() !==
                                new Date(messages.records?.[i - 1]?.createdAt)?.getDate();

                            return (
                              <>
                                {isMsgSent ? (
                                  <div
                                    key={message._id}
                                    className="w-fit d-flex align-self-end shadow-sm bg-secondary text-white my-2 p-1 rounded"
                                    style={{ maxWidth: "75%" }}
                                  >
                                    <div className="me-1">
                                      <p className="d-flex text-medium m-0 ms-2 text-break">
                                        {message.message}
                                      </p>
                                      <p className="text-small text-nowrap text-end mb-0 ms-2 me-1">
                                        {format_time(message.createdAt)}
                                      </p>
                                    </div>
                                    <img
                                      src={getUserImage(userProfile)}
                                      alt={userProfile.name}
                                      className="img-fluid rounded-circle"
                                      style={{ width: 25, height: 25 }}
                                      onError={(e) => {
                                        e.target.src = getUserImage();
                                      }}
                                    />
                                  </div>
                                ) : (
                                  <div
                                    key={message._id}
                                    className="w-fit d-flex shadow-sm bg-primary text-white my-2 p-1 rounded"
                                    style={{ maxWidth: "75%" }}
                                  >
                                    <img
                                      src={getUserImage(message.receiver)}
                                      alt={message.receiver.name}
                                      className="img-fluid rounded-circle"
                                      style={{ width: 25, height: 25 }}
                                      onError={(e) => {
                                        e.target.src = getUserImage();
                                      }}
                                    />
                                    <p className="d-flex justify-content-center text-medium m-0 ms-2">
                                      <span className="text-break">{message.message}</span>
                                      <span className="text-small text-nowrap ms-2 me-1 align-self-end">
                                        {format_time(message.createdAt)}
                                      </span>
                                    </p>
                                  </div>
                                )}
                                {(i === messages?.records?.length - 1 || isNewDateStarted) && (
                                  <p className="text-medium text-center text-muted my-2">
                                    {format_date(message.createdAt)}
                                  </p>
                                )}
                              </>
                            );
                          })}
                        </InfiniteScroll>
                      </>
                    ) : messages.totalCount === 0 ? (
                      <div className="h-100 d-flex align-items-center justify-content-center">
                        <img
                          src={noMessagePlaceholder}
                          alt={`Start chatting by saying hello to ${selectedUser.name}`}
                          className="img-fluid rounded"
                          style={{ maxWidth: 300 }}
                        />
                      </div>
                    ) : (
                      <div className="d-flex align-items-center justify-content-center h-100">
                        <ClipLoader
                          color={"var(--primary-color)"}
                          cssOverride={{ borderWidth: 5, width: 50, height: 50 }}
                          aria-label="Loading Spinner"
                          data-testid="loader"
                        />
                      </div>
                    )}
                  </div>

                  {/* Chat Footer */}
                  {selectedUser.blockedUser ? (
                    <p
                      className="m-0 py-2 d-flex align-items-center justify-content-center"
                      style={{ height: 58 }}
                    >
                      You are not allowed to send message in this chat
                    </p>
                  ) : (
                    <form onSubmit={handleSendMessage} className="py-2">
                      <div className="messageBox d-flex align-items-center">
                        <div ref={emojiPickerRef} className="position-relative ms-2">
                          <h4
                            className="pointer m-0"
                            onClick={() => {
                              setOpendEmojiPicker((prev) => !prev);
                            }}
                          >
                            {String.fromCodePoint("0x" + "1f603")}
                          </h4>
                          <EmojiPicker
                            className="emojiPicker position-absolute"
                            skinTonesDisabled={true}
                            open={openEmojiPicker}
                            onEmojiClick={(e) => {
                              setNewMessage((prev) => `${prev} ${e.emoji}`);
                              console.log("emoji", e);
                            }}
                            previewConfig={{ showPreview: false }}
                          />
                        </div>
                        <div className="d-flex align-items-center shadow-sm mx-2 border rounded pe-2 w-100">
                          <textarea
                            ref={messageBoxRef}
                            type="text"
                            className="form-control border-0 outline-0 shadow-none"
                            style={{ maxHeight: 130 }}
                            placeholder="Type a message"
                            value={newMessage}
                            onChange={handleMessageChange}
                            onKeyDown={(e) => {
                              if (e.key === "Enter" || e.keyCode === 13) {
                                handleSendMessage(e);
                              }
                            }}
                          />
                          <button type="submit" className="textBtn text-dark">
                            <BsSendFill />
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </>
              ) : (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <div className="text-center">
                    <img
                      src={noChatSelectedPlaceholder}
                      alt="Start chatting with your favourite person"
                      className="img-fluid rounded-circle"
                      style={{ maxWidth: 300 }}
                    />
                    <h5 className="my-4">Start chatting with your favourite person</h5>
                  </div>
                </div>
              )}
            </div>
          </Col>
        </Row>
      </section>

      {(userAction?.action === "blockUser" || userAction.action === "unblockUser") && (
        <BlockChat
          userAction={userAction}
          setUserAction={setUserAction}
          handleBlock={handleBlockUser}
        />
      )}
    </>
  );
}
