import React from "react";
import Modal from "react-bootstrap/Modal";
import { FaBookmark, FaRegBookmark } from "react-icons/fa6";
import { ReactComponent as CloseIcon } from "../../assets/icons/closeIcon.svg";
import successGif from "../../assets/images/success3.gif";
import { useNavigate } from "react-router-dom";

export default function BookmarkSuccess({ userAction, setUserAction }) {
  const navigate = useNavigate();

  const handleClose = () => {
    setUserAction(null);
  };

  return (
    <Modal
      show={userAction}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="modal_uploadcv"
    >
      <Modal.Body className="p-0">
        <div className="position-relative p-3 pt-5">
          <p
            className="pointer position-absolute "
            style={{ right: -10, top: -10 }}
            onClick={handleClose}
          >
            <CloseIcon />
          </p>

          <div className="modelBookmarkIcon text-center my-4">
            {userAction?.action === "bookmarkProgress" ? (
              ""
            ) : userAction?.isBookmarked ? (
              <FaBookmark className="shadow" />
            ) : (
              <FaRegBookmark className="shadow" />
            )}
          </div>

          <h6 className="text-center">
            {userAction?.action === "bookmarkProgress"
              ? "Updating your saved directory!!"
              : userAction?.isBookmarked
              ? "Story added in your saved directory!!"
              : "Story removed from your saved directory!!"}
          </h6>

          <div className="text-center my-4">
            {userAction?.action === "bookmarkProgress" ? (
              <i className="bookmarkModelIcon spinner-grow ms-2" role="status" />
            ) : (
              <img src={successGif} className="bookmarkModelIcon img-fluid rounded-circle" />
            )}
          </div>
          <div className="d-flex justify-content-center">
            <button className="primaryBtn rounded px-4 py-1" onClick={() => navigate("/")}>
              View saved directory
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
