import { createSlice } from "@reduxjs/toolkit";
import { getUserProfile, login, logoutUser } from "./thunk";

const initialState = {
  loggedinUser: {},
  rememberedUser: {},
  userProfile: {},
};

const slice = createSlice({
  name: "auth",
  initialState: { ...initialState },
  reducers: {
    logout: (state, action) => {
      state.loggedinUser = {};
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state, action) => {})
      .addCase(login.fulfilled, (state, action) => {
        state.loggedinUser = action.payload?.data || {};
        state.rememberedUser = action.meta?.arg?.remember ? action.meta?.arg : {};
      })
      .addCase(login.rejected, (state, action) => {});
    builder
      .addCase(getUserProfile.pending, (state, action) => {})
      .addCase(getUserProfile.fulfilled, (state, action) => {
        state.userProfile = action.payload?.data || {};
      })
      .addCase(getUserProfile.rejected, (state, action) => {});
    builder
      .addCase(logoutUser.pending, (state, action) => {})
      .addCase(logoutUser.fulfilled, (state, action) => {
        state.loggedinUser = {};
      })
      .addCase(logoutUser.rejected, (state, action) => {});
  },
});

export default slice.reducer;
export const { logout } = slice.actions;
