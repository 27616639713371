import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const addQuery = createAsyncThunk("queries/addQuery", async (request, Thunk) => {
  try {
    let response = await axios.post("query/add", request);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getQueriesList = createAsyncThunk("queries/getQueriesList", async (request, Thunk) => {
  try {
    let response = await axios.post("queries/list", request);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});
