import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getOtherUserProfile = createAsyncThunk(
  "otherUserProfile/getOtherUserProfile",
  async (data, Thunk) => {
    try {
      let response = await axios.post("profile/others", data);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);

export const reportUser = createAsyncThunk("otherUserProfile/reportUser", async (data, Thunk) => {
  try {
    let response = await axios.post("report/user", data);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});
