import { createSlice } from "@reduxjs/toolkit";
import { getCategories, getContentPages } from "./thunk";

const initialState = {
  categories: [],
  showLoginPop: false,
  contentPagesList: [],
};

const commonSlice = createSlice({
  name: "commonSlice",
  initialState: { ...initialState },
  reducers: {
    manageLoginPop: (state, action) => {
      state.showLoginPop = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCategories.pending, (state, action) => {})
      .addCase(getCategories.fulfilled, (state, action) => {
        state.categories = action.payload?.data || [];
      })
      .addCase(getCategories.rejected, (state, action) => {});
    builder
      .addCase(getContentPages.pending, (state, action) => {})
      .addCase(getContentPages.fulfilled, (state, action) => {
        state.contentPagesList = action.payload?.data || [];
      })
      .addCase(getContentPages.rejected, (state, action) => {});
  },
});

export default commonSlice.reducer;
export const { manageLoginPop } = commonSlice.actions;
