import { createSlice } from "@reduxjs/toolkit";
import { getMainStoriesList } from "./thunk";

const getStoriesData = (oldData = {}, newData = {}, page = 1) => {
  const data =
    page === 1
      ? newData || {}
      : {
          ...(newData || {}),
          records: [...(oldData?.records || []), ...(newData?.records || [])],
        };

  return data;
};

const initialState = {
  loadingStoriesList: false,
  storiesList: {},
  myStoriesList: {},
  privateStoriesList: {},
};

const slice = createSlice({
  name: "stories",
  initialState: { ...initialState },
  reducers: {
    updateStoriesList: (state, action) => {
      const { listFor } = action.payload || {};
      if (listFor === "private") {
        state.privateStoriesList = action.payload;
      } else if (listFor === "user") {
        state.myStoriesList = action.payload;
      } else {
        state.storiesList = action.payload;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMainStoriesList.pending, (state, action) => {
        state.loadingStoriesList = true;
      })
      .addCase(getMainStoriesList.fulfilled, (state, action) => {
        state.loadingStoriesList = false;
        const { listFor, page } = action.payload || {};

        if (listFor === "user") {
          state.myStoriesList = getStoriesData(
            state.myStoriesList,
            action.payload?.data || {},
            page
          );
        } else if (listFor === "private") {
          state.privateStoriesList = getStoriesData(
            state.privateStoriesList,
            action.payload?.data || {},
            page
          );
        } else {
          state.storiesList = getStoriesData(state.storiesList, action.payload?.data || {}, page);
        }
      })
      .addCase(getMainStoriesList.rejected, (state, action) => {
        state.loadingStoriesList = false;
      });
  },
});

export default slice.reducer;
export const { updateStoriesList } = slice.actions;
