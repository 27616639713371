import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IoIosArrowForward } from "react-icons/io";
import * as Path from "../../routes/routesPath";
import { getMyAccountPagePath, getUserImage } from "../../utils/helpers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { logout } from "../../redux/auth/slice";
import { logoutUser } from "../../redux/auth/thunk";
import DeletePopup from "../../Component/Modals/deletePop";
import { isArray } from "../../utils/formatersAndParsers";

export const userCenter = [
  { label: "My Account", path: getMyAccountPagePath() },
  { label: "My Stories", path: getMyAccountPagePath(Path.storiesRoute) },
  {
    label: "Saved Stories",
    path: getMyAccountPagePath(Path.bookmarkedStoriesRoute),
  },
  {
    label: "Private Directory",
    path: getMyAccountPagePath(Path.privateDirectoryRoute),
  },
  {
    label: "Feedback and Queries",
    path: getMyAccountPagePath(Path.myQueriesRoute),
  },
  // { value: "replies", label: "Comments and Replies", path: "" },
];

export default function MyAccountSideOptions({ wrapperClassName = "", handleClose = () => {} }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { userProfile } = useSelector((state) => state.auth || {});
  const { contentPagesList, categories } = useSelector((state) => state.common || {});

  const [userAction, setUserAction] = useState(null);

  const handleLogout = async () => {
    dispatch(logout());
    handleClose();
    navigate(Path.homeRoute);
    await handleApiRequest(logoutUser, {}, false);
  };

  return (
    <>
      <div className={`profileOptions ${wrapperClassName}`}>
        <div className="text-center">
          <img
            className="userImage img-fluid object-fit-cover rounded-circle mt-3 mb-1"
            src={getUserImage(userProfile)}
            alt={!userProfile ? "userImage" : userProfile?.name}
            onError={(e) => {
              e.target.src = getUserImage();
            }}
          />
          <p className="m-0">{userProfile?.name}</p>
          <p className="muted small m-0" style={{ lineHeight: "12px" }}>
            {userProfile?.email}
          </p>
        </div>
        <div className="profileItemsList mt-3">
          <div className="mx-2">
            <div className="px-2">
              {userCenter.map((option, i) => (
                <p
                  key={option.path}
                  className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary`}
                  onClick={() => {
                    navigate(option.path);
                    handleClose();
                  }}
                >
                  <span>{option.label}</span>
                  <span>
                    <IoIosArrowForward />
                  </span>
                </p>
              ))}
            </div>
          </div>
          <div className="mx-2 my-3">
            <h5 className=" mb-1">About Us</h5>
            <p className="border-enclined" />
            <div className="px-2">
              <p
                className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary`}
                onClick={() => {
                  navigate(Path.contactUsRoute);
                  handleClose();
                }}
              >
                <span>Contact us</span>
                <span>
                  <IoIosArrowForward />
                </span>
              </p>
              {isArray(contentPagesList).map((page) => (
                <p
                  key={page._id}
                  className={`profileItem mb-0 pt-2 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary`}
                  onClick={() => {
                    navigate(`${Path.cmsPage}/${page._id}`);
                    handleClose();
                  }}
                >
                  <span>{page.title}</span>
                  <span>
                    <IoIosArrowForward />
                  </span>
                </p>
              ))}
            </div>
          </div>
          <div className="px-2">
            <p
              className="mx-2 mb-3 mt-4 pb-1 d-flex align-items-center justify-content-between border-bottom border-secondary"
              onClick={() => setUserAction({ action: "logout" })}
            >
              <span>Logout</span>
              <span>
                <IoIosArrowForward />
              </span>
            </p>
          </div>
        </div>
      </div>
      {userAction?.action === "logout" && (
        <DeletePopup
          userAction={userAction}
          setUserAction={setUserAction}
          onSubmit={handleLogout}
          submitLabel={"Logout"}
        />
      )}
    </>
  );
}
