import axios from "axios";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getChatsList = createAsyncThunk("chat/getChatsList", async (request, Thunk) => {
  try {
    let response = await axios.post("chats/list", request);
    return response?.data;
  } catch (error) {
    return Thunk.rejectWithValue(error);
  }
});

export const getMessageHistory = createAsyncThunk(
  "chat/getMessageHistory",
  async (request, Thunk) => {
    try {
      let response = await axios.post("chat/history", request);
      return response?.data;
    } catch (error) {
      return Thunk.rejectWithValue(error);
    }
  }
);
