import { HiHandThumbUp } from "react-icons/hi2";
import { FaHandHoldingHeart, FaHeart } from "react-icons/fa6";
import { TbBulbFilled } from "react-icons/tb";
import { FaLaughBeam } from "react-icons/fa";

const {
  REACT_APP_INSTAGRAM_URL,
  REACT_APP_FACEBOOK_URL,
  REACT_APP_YOUTUBE_URL,
  REACT_APP_TELEGRAM_URL,
} = process.env;

export const skeletonBaseColor = "#d3d3d3";
export const skeletonHighlightColor = "#e0e0e0";

export const passwordregex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export const maleAvatarHolder = "/images/userImage.png";
export const femaleAvatarHolder = "/images/userImage-girl.png";

export const genderOptions = [
  { value: "male", label: "Male" },
  { value: "female", label: "Female" },
  { value: "other", label: "Other" },
];

export const userReportReasons = [
  { value: "nudityOrSexualActivity", label: "Nudity or sexual activity" },
  { value: "hateSpeech", label: "Hate speech" },
  { value: "scamOrFraud", label: "Scam or fraud" },
  { value: "bullyingOrHarassment", label: "Bullying or harassment" },
  { value: "saleOfIllegalProducts", label: "Sale of illegal products" },
  { value: "violenceOrDanger", label: "Violence or danger" },
  { value: "spam", label: "Spam" },
];

export const storyReportReasons = [
  { value: "abusive", label: "Abusive" },
  { value: "askForMoney", label: "Ask for money" },
  { value: "violence", label: "Violence" },
  { value: "hateSpeech", label: "Hate speech" },
  { value: "falseInformation", label: "False information" },
];

export const sortingOptions = [
  { value: "relevance", label: "Relevance", orderBy: "_id", order: -1 },
  { value: "latestFirst", label: "Latest first", orderBy: "createdAt", order: -1 },
  { value: "oldestFirst", label: "Oldest first", orderBy: "createdAt", order: 1 },
];

export const defaultPage = {
  orderBy: "_id",
  order: -1,
  page: 1,
  limit: 20,
};

export const limitOptions = [
  { value: 20, label: 20 },
  { value: 50, label: 50 },
  { value: 75, label: 75 },
  { value: 100, label: 100 },
];

export const reactionsIcons = {
  like: <HiHandThumbUp style={{ color: "#2684ff" }} />,
  love: <FaHeart style={{ color: "red" }} />,
  laugh: <FaLaughBeam style={{ color: "orange" }} />,
  information: <TbBulbFilled style={{ color: "#ffe000", width: 30, height: 30 }} />,
  support: <FaHandHoldingHeart style={{ color: "#c90000" }} />,
};

export const contactUsReason = [
  { value: "suggestionOrFeedback", label: "Suggestion or Feedback" },
  { value: "problemOrComplaint", label: "Problem or Complaint" },
  {
    value: "reportIllegalOrInappropriateContent",
    label: "Report illegal or Inappropriate content",
  },
];

export const socialHandles = {
  instagram: REACT_APP_INSTAGRAM_URL,
  facebook: REACT_APP_FACEBOOK_URL,
  youtube: REACT_APP_YOUTUBE_URL,
  telegram: REACT_APP_TELEGRAM_URL,
};

// SEO data
export const defaultDescription =
  "Feelinghub is a unique platform where you can share your feelings, emotions, and problems with others. You can also share anonymously or save as private for you...";
export const mainKeywords =
  "FeelingHub, share feelings, emotions, problems, share anonymously, diary";
