import React, { useEffect, useState } from "react";
import { Col, Form, Nav, Row, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import { genderOptions } from "../../utils/constants";
import { profileSchema, updatePasswordSchema } from "../../utils/validationSchemas";
import { handleTransformValues } from "../../utils/formatersAndParsers";
import { handleApiRequest } from "../../services/handleApiRequest";
import { getUserProfile, updatePassword, updateUserProfile } from "../../redux/auth/thunk";
import MyForm from "../../Component/formComponent";
import { successMsg } from "../../utils/toastMessage";

export const profileUpdateFields = [
  {
    value: "name",
    type: "text",
    placeholder: "Enter full name",
    label: "Full Name",
  },
  { value: "mobile", type: "phone" },
  { value: "birth_date", type: "date" },
  { value: "gender", type: "select", options: genderOptions },
  { value: "address", type: "text" },
  { value: "avatar", type: "file" },
];

export const changePasswordFields = [
  { value: "oldPassword", type: "password" },
  { value: "password", type: "password" },
  { value: "confirmPassword", type: "password" },
];

const defaultValues = {
  name: "",
  mobile: "",
  gender: "",
  avatar: "",
  address: "",
  birth_date: "",
};
const defaultPasswordValues = {
  oldPassword: "",
  newPassowrd: "",
  confirmPassword: "",
};

const UpdateProfile = () => {
  const { userProfile } = useSelector((state) => state.auth);
  const [userAction, setUserAction] = useState(null);

  const handleUserProfile = async () => {
    await handleApiRequest(getUserProfile);
  };

  const handleUpdateProfile = async () => {
    try {
      setUserAction({ action: "updatingProfile" });

      const request = await handleTransformValues(values);
      const response = await handleApiRequest(updateUserProfile, request);
      setUserAction(null);

      if (response.status) {
        successMsg("Profile updated successfully!!");
        handleUserProfile();
      }
    } catch (error) {
      setUserAction(null);
    }
  };

  const handleUpdatePassword = async () => {
    try {
      setUserAction({ action: "updatingPassword" });

      const response = await handleApiRequest(updatePassword, passwordForm.values);
      setUserAction(null);

      if (response.status) {
        successMsg("Password updated successfully!!");
        passwordForm.resetForm();
      }
    } catch (error) {
      setUserAction(null);
    }
  };

  let {
    values,
    errors,
    handleChange,
    setFieldValue,
    setValues,
    handleSubmit,
    setSubmitting,
    isSubmitting,
  } = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: defaultValues,
    validationSchema: profileSchema,
    onSubmit: handleUpdateProfile,
  });

  const passwordForm = useFormik({
    validateOnChange: false,
    validateOnBlur: false,
    initialValues: defaultPasswordValues,
    validationSchema: updatePasswordSchema,
    onSubmit: handleUpdatePassword,
  });

  useEffect(() => {
    if (userProfile._id) {
      setValues({ ...defaultValues, ...userProfile });
    }
  }, [userProfile]);

  return (
    <div className="w-100 overflow-auto">
      <div className="updateProfileContainer h-100 my-3">
        <div className="mx-2 py-2 w-100">
          <Tab.Container id="left-tabs-example" defaultActiveKey="profile">
            <div className="top">
              <Nav
                variant="pills"
                className="borderPillsTab d-flex justify-content-center align-items-center"
                defaultActiveKey="profile"
              >
                <Nav.Item className="w-100">
                  <Nav.Link
                    className="bg-transparent text-nowrap px-lg-5 rounded-0 py-1 text-dark d-flex align-items-center justify-content-center"
                    eventKey="profile"
                  >
                    Profile
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="w-100">
                  <Nav.Link
                    className="bg-transparent text-nowrap px-lg-5 rounded-0 py-1 text-dark d-flex align-items-center justify-content-center"
                    eventKey="changePassword"
                  >
                    Change Password
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>

            <Tab.Content>
              <Tab.Pane eventKey="profile">
                <div className="updateProfileWrapper shadow position-relative p-4">
                  <Form className="signup_form inputall_same" onSubmit={handleSubmit}>
                    <MyForm
                      formFields={profileUpdateFields}
                      values={values}
                      setFieldValue={setFieldValue}
                      errors={errors}
                      handleChange={handleChange}
                      setSubmitting={setSubmitting}
                    />

                    {userAction?.action === "updatingProfile" ? (
                      <button
                        className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center"
                        disabled
                      >
                        Updating your account
                        <i className="growLoader spinner-grow ms-2" role="status" />
                      </button>
                    ) : (
                      <button
                        type="submit"
                        className="primaryBtn rounded w-100"
                        disabled={isSubmitting}
                      >
                        {isSubmitting ? (
                          <>
                            Uploading your avatar
                            <i className="growLoader spinner-grow ms-2" role="status" />
                          </>
                        ) : (
                          "Update Profile"
                        )}
                      </button>
                    )}
                  </Form>
                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="changePassword">
                <div className="updateProfileWrapper shadow position-relative p-4">
                  <Form className="signup_form inputall_same" onSubmit={passwordForm.handleSubmit}>
                    <MyForm
                      formFields={changePasswordFields}
                      values={passwordForm.values}
                      setFieldValue={passwordForm.setFieldValue}
                      errors={passwordForm.errors}
                      handleChange={passwordForm.handleChange}
                    />

                    {userAction?.action === "updatingPassword" ? (
                      <button
                        className="secondaryBtn muted rounded w-100 d-flex align-items-center justify-content-center"
                        disabled
                      >
                        Updating your password
                        <i className="growLoader spinner-grow ms-2" role="status" />
                      </button>
                    ) : (
                      <button type="submit" className="primaryBtn rounded w-100">
                        Update Password
                      </button>
                    )}
                  </Form>
                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>
    </div>
  );
};

export default UpdateProfile;
